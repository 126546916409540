import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Layout from "../component/Layout/Layout";
import Hero from "../component/Hero/Hero";
import BlogPosts from "../component/Posts/BlogPosts";
import StickyBox from "../component/StickyBox/StickyBox";
import Stars from "../component/Stars/Stars.jsx";
import CiteSvg from "../assets/images/icons/opinie.svg";
import "../assets/styles/article-page.sass";
import "../assets/styles/opinions.sass";
import CompareWidget from "../component/CompareWidget/CompareWidget";
import {useStaticQuery, graphql} from "gatsby";
import Seo from "../component/Seo/Seo";

const Opinions: React.FC = () => {
  const {wpPage, allWpOpinia} = useStaticQuery(graphql`
    query OpinionsSeo {
      wpPage(slug: {eq: "opinie-o-ikze"}) {
        seo {
          metaDesc
          title
          focuskw
        }
        opisStrony {
          opis
        }
      }
      allWpOpinia {
        edges {
          node {
            opinion {
              rating
              name
              opinion
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title={wpPage.seo.title}
        description={wpPage.seo.metaDesc}
        keywords={wpPage.seo.metaDesc}
        pathname="/opinie-o-ikze/"
      />
      <Hero
        heading={`Opinie o IKZE
        <br/>
        - czy warto?`}
        image={
          <StaticImage
            src="../assets/images/photo/hero-opinion.jpg"
            alt="Opinie o IKZE - czy warto?"
            title="Opinie o IKZE - czy warto?"
            height={600}
            width={828}
            quality={90}
          />
        }
        paragraph={wpPage.opisStrony.opis}
      />
      <div className="container mw-1400">
        <div className="row">
          <div className="col-xl-8 article">
            <p className="text-big font-weight-bold">
              IKZE działa już ponad dekadę, a w obecnym kształcie niewiele krócej. To dość czasu, by można było sformułować na jego temat wyważone, potwierdzone konkretnymi analizami i doświadczeniami opinie.
            </p>
            <div className="opinions">
              {/* {allWpOpinia.edges.map((opinion: any, i: number) => {
                return (
                  <div className="opinion" key={i}>
                    <img className="cite" src={CiteSvg} alt="" title="Opinia" />
                    <div className="heading">
                      {opinion.node.opinion.name}<Stars rating={opinion.node.opinion.rating} />
                    </div>
                    <p>{opinion.node.opinion.opinion}</p>
                  </div>
                );
              })} */}
              <div className="opinion" key={1}>
                <img className="cite" src={CiteSvg} alt="" title="Opinia" />
                <div className="heading">
                  <h3>
                    Waldemar Pawlak,<br /><small className="position">były prezes Rady Ministrów</small>  
                  </h3>
                  <Stars rating="5" />
                </div>
                <p>Nie za bardzo wierzę w państwowe emerytury. Staram się zabezpieczyć sobie przyszłość przez oszczędności i dobre relacje z dziećmi.<br /><small>Źródło: TVN24</small></p>
              </div>
              <div className="opinion" key={2}>
                <img className="cite" src={CiteSvg} alt="" title="Opinia" />
                <div className="heading">
                  <h3>
                  Gertruda Uścińska,<br /><small className="position">Prezes ZUS</small>  
                  </h3>
                  <Stars rating="5" />
                </div>
                <p>W niedalekiej perspektywie konieczne jest to, by emerytura pochodziła z różnych źródeł. Emerytura z FUS będzie częścią kluczową, ale potrzebne są zdywersyfikowane źródła, takie jak PPK, IKE, IKZE.<br /><small>Źródło: Radio Zet, Gość Radia Zet, 29.09.2019</small></p>
              </div>
              <div className="opinion" key={3}>
                <img className="cite" src={CiteSvg} alt="" title="Opinia" />
                <div className="heading">
                  <h3>
                  Danuta Musiał,<br /><small className="position">Generali Investments TFI</small>  
                  </h3>
                  <Stars rating="4.7" />
                </div>
                <p>Inwestując w III filar, nie trzeba martwić się o niekorzystne zmiany w polskim systemie emerytalnym. Gromadzone pieniądze są prywatną własnością osoby oszczędzającej i stanowią gwarancję dodatkowej emerytury.<br /><small>Źródło: Newseria.pl</small></p>
              </div>
              <div className="opinion" key={4}>
                <img className="cite" src={CiteSvg} alt="" title="Opinia" />
                <div className="heading">
                  <h3>
                  Jarosław Sadowski,<br /><small className="position">główny analityk Ekspander Polska</small>  
                  </h3>
                  <Stars rating="4.5" />
                </div>
                <p>Jeśli okaże się, że nasza emerytura wyniesie 20-30 procent naszego ostatniego wynagrodzenia, to tego rodzaju dodatkowe oszczędności (IKZE) zdecydowanie ją podniosą. Bez nich poziom naszego życia po przejściu na emeryturę zdecydowanie by się obniżył.<br /><small>Źródło: Polskieradio24</small></p>
              </div>

              <div className="opinion" key={5}>
                <img className="cite" src={CiteSvg} alt="" title="Opinia" />
                <div className="heading">
                  <h3>
                  Paweł Mizerski,<br /><small className="position">UNIQA TFI, Dyrektor Departamentu</small>  
                  </h3>
                  <Stars rating="4.5" />
                </div>
                <p>Na IKZE warto odkładać pieniądze głównie dlatego, że bazując tylko na obowiązkowej części systemu emerytalnego, odkładamy zdecydowanie mniejszą kwotę, która po naszym przejściu na emeryturę nie będzie odpowiadała kwocie, którą otrzymujemy, pracując. W stosunku do rozwiązań publicznych dodatkową korzyścią jest też możliwość dziedziczenia tych środków. Musimy również pamiętać o tym, że jeśli sytuacja życiowa nas zmusi do skorzystania z tych środków wcześniej, jest również taka możliwość.<br /><small>Źródło: Newseria Biznes</small></p>
              </div>

              <div className="opinion" key={6}>
                <img className="cite" src={CiteSvg} alt="" title="Opinia" />
                <div className="heading">
                  <h3>
                  Daniel Szewieczek,<br /><small className="position">dyrektor ING Banku Śląskiego</small>  
                  </h3>
                  <Stars rating="4.5" />
                </div>
                <p>Warto pamiętać, że IKE oraz IKZE są w pełni dziedziczone. Każdy może wskazać osobę, która przejmie środki po jej śmierci. W takim przypadku zgromadzone pieniądze nie przepadają i nie trafiają do budżetu.<br /><small>Źródło: Money.pl</small></p>
              </div>

            </div>
            <div className="question">
              <h2 style={{marginTop: "20px", marginBottom: "20px"}}>
                Co Polacy myślą o odkładaniu na emeryturę? Poznaj najpopularniejsze opinie.
              </h2>
              <div className="line"></div>
              <p className="text">
              Według badań wykonanych przy współudziale Polskiej Izby Ubezpieczeń, na emeryturę nie odkłada niemal połowa Polaków. Wielu z nich, mimo konsekwentnie obniżającej się stopy zastąpienia uważa, że nie ma takiej potrzeby. Specjaliści od dekad próbują ustalić motywy stojące za obserwowanymi w społeczeństwie postawami. 
              <br /><br />
              Uznaje się, że utrzymanie publicznego Funduszu Ubezpieczeń Społecznych jest konieczne ze względu na braki w edukacji finansowej społeczeństwa oraz oczekiwanie, że, tak czy inaczej, państwo powinno zadbać o nasz los. 
              <br /><br />
              Przywykliśmy do życia w systemie gwarantującym pewien poziom opieki, jednak jego wydolność, m.in. z powodu starzenia się społeczeństwa,maleje. Od lat nie brakuje wokół nas emerytów, których położenie, oględnie mówiąc, nie budzi zazdrości. To już nie są tylko opinie, ale fakty: obowiązkowa repartycyjna część naszego systemu emerytalnego nie może zapewnić nam godnej emerytury. Trzeba działać. Tylko jak?
              </p>

              <h2 style={{marginTop: "20px", marginBottom: "20px"}}>
                Dlaczego Polacy nie ufają systemowi emerytalnemu?
              </h2>
              <h4 className="primary">„Nic mi nie dadzą, a jeszcze okradną!” – Czy taka opinia ma dziś podstawy?</h4>
              <div className="line mt-3"></div>
              <p className="text">
                Otwarte Fundusze Emerytalne (OFE) były rozwiązaniem obarczonym poważnymi wadami. Kosztownym, uzależniającym los przyszłych emerytów od rynków finansowych, stanowiącym niezwykle odważny na tle innych państw krok w kierunku prywatyzacji systemu. Stan ten wymagał zmiany, jednak sposób, w jaki nastąpiła, przełożył się na opinie społeczeństwa na temat celowości korzystania z publicznych programów.
              </p>
              <p className="text font-weight-bold">
                Wśród części fachowców rozpowszechniona jest opinia, że to właśnie wady oraz likwidacja OFE należą do głównych przyczyn braku zaufania Polaków do nowych propozycji programów emerytalnych.
              </p>
              <p className="text">
                Mimo że likwidacja OFE była potrzebna, a transformacja środków dokonana w 2014 roku  miała charakter roszady księgowej, działanie to wzbudziło w nas obawy, że jedna decyzja władzy może przekreślić długoletnie owoce oszczędzania. 
              </p>
              <p className="text">
                Uzasadnione są opinie, że nasz system emerytalny nie jest tak prosty i przejrzysty, jak być powinien. Zapewne niewielki procent przyszłych emerytów potrafi w ogóle prawidłowo rozwinąć wszystkie skróty i wyjaśnić pojęcia (OFE, PPK, PPE, IKE, IKE Plus, IKZE, FUS i ZUS z jego kontem i subkontem) związane z systemem emerytalnym.
              </p>
              <p className="text">
                Trzeba jednak zmierzyć się z tą materią i ustalić, skąd wzięły się opinie na temat poszczególnych elementów systemu emerytalnego i które są uzasadnione. <span className="font-weight-bold">IKZE, jak już wiemy, jest rozwiązaniem bardzo prostym i pozostawia w gestii właściciela kontrolę nad kontem i sposobem inwestowania pieniędzy.</span> Już z tego powodu część wątpliwości jest chybiona.
              </p>

              <h2 style={{marginTop: "20px", marginBottom: "20px"}}>
                Prawdziwe czy wydumane wady IKZE?  Sprawdzamy!
              </h2>
              <div className="line"></div>
              <p className="text">
                Niekiedy wymienia się także i pewne słabości Indywidualnego Konta Zabezpieczenia Emerytalnego. Czy są one poważne, albo czy w ogóle te opinie mają uzasadnienie? Przyjrzymy im się bliżej.
              </p>
              <h3>Niskie limity wpłat</h3>
              <p className="text">
                Limit wpłat na IKZE omówiliśmy szczegółowo w osobnym artykule, więc krótko przypomnijmy: dla statystycznego Kowalskiego z możliwościami jego domowego budżetu, limity wpłat na IKZE w zupełności wystarczą. To właśnie do osób o średnim i minimalnym wynagrodzeniu skierowany jest ten program inwestycyjny. Ulga podatkowa ma na celu pomóc w oszczędzaniu na emeryturę statystycznym Polakom, a nie wielkim inwestorom, którzy mogliby wpłacać rocznie dziesiątki tysięcy złotych.
              </p>


              <h3>Ograniczona swoboda wycofywania środków</h3>
              <p className="text">
                Oczywiście likwidacja rachunku może nastąpić w dowolnym momencie, jednak nieco uderzy nas to po kieszeni. Czy to źle? Musimy mieć motywację do długofalowego oszczędzania, dlatego zrozumiałe jest także utrudnienie wykorzystywania IKZE do innych celów niż emerytura. Ograniczenia w wypłacie środków z IKZE rekompensuje nam pełna swoboda w zmianie funduszy w ramach rachunku.
              </p>

              <h3>Ograniczone pole wyboru instrumentów inwestycyjnych</h3>
              <p className="text">
                W IKZE skorzystamy tylko z instrumentów kapitałowych. Jest to związane z możliwościami i potrzebami głównych beneficjentów programu – klasy średniej. Pracownicy o przeciętnych i średnich zarobkach nie będą inwestować, np. w ziemię czy inne nieruchomości, bo ich na to nie stać. Z tego też powodu emerytalny program inwestycyjny powinien być prosty, z czym trudno byłoby pogodzić inwestowanie w inne teoretycznie perspektywiczne dobra.
              </p>

              <h3>Otwarta konstrukcja stawiająca klienta przed wyborami</h3>
              <p className="text">
                Jak to zwykle bywa, opinie bywają skrajnie różne - jednym przeszkadza niedostatek swobody, innym nadmiar. IKZE to prosty w swojej istocie parasol podatkowy, a co się pod nim znajdzie, to już sprawa właściciela rachunku. Dokonanie wyboru nie musi być jednak takie trudne – jeśli mało wiemy na temat produktów finansowych, wybieramy po prostu te najmniej ryzykowne i zbierające dobre opinie. Jeśli wiemy więcej o funduszach inwestycyjnych – prostota IKZE staje się ogromną zaletą. Koniec końców są to nasze środki i to my nimi dysponujemy!
              </p>

              <h3>Brak szerokiego wsparcia dla klienta przy zakupie</h3>
              <p className="text">
                Co tu dużo mówić – rzeczywiście nie jest to ten rodzaj produktu, który doradcy finansowi usiłują sprzedać jak największej liczbie klientów. Powód jest oczywisty - nie należy on do tych przynoszących instytucjom finansowym największe zyski. Sami musimy rozpoznać swoje potrzeby i ustalić, jaki program oszczędzania na emeryturę będzie dla nas najlepszy. Nasz ranking i kalkulator są właśnie po to, by w tym pomóc.
              </p>

              <h2 style={{marginTop: "20px", marginBottom: "20px"}}>
                IKZE – opinie naszych analityków
              </h2>
              <div className="line mb-3"></div>
              <h3>Czy warto posiadać IKZE?</h3>
              <p className="text">
                To ponownie jest kwestia nie tyle opinii, ile najprostszych faktów, które przedstawiliśmy w <a href="https://www.rankingikze.pl/blog-emerytalny/ike-na-czym-polega/">artykule o korzyściach IKZE</a>. Indywidualne Konto Zabezpieczenia Emerytalnego to efektywne narzędzie oszczędzania, więc opinia na ten temat może być tylko jedna! Warto, bo to po prostu się opłaca, nawet jeśli nie znamy się na produktach inwestycyjnych i nie sięgniemy po ryzykowne i potencjalnie najbardziej zyskowne portfele. Korzyści będziemy czerpać zwłaszcza gromadząc i inwestując także uzyskiwane dzięki kolejnym wpłatom zwroty podatków.
              </p>
              <h3>Kto powinien założyć sobie IKZE?</h3>
              <p className="text">W zasadzie można powiedzieć, że każdy i na tym zamknąć temat. Jeśli jednak postaramy się ściślej potraktować to zagadnienie, to z grona osób, którym IKZE będzie bezwzględnie przydatne, wypadają dwie wąskie skrajne grupy.</p>
              <ul>
                <li style={{fontSize: "20px"}}>Ci, którzy zarabiają tyle, że temat zdobycia środków na godną starość dla nich nie istnieje,</li>
                <li style={{fontSize: "20px"}}>Ci, którzy jeszcze nie zarabiają i nie skorzystają na odpisie podatkowym, bądź z racji młodego wieku objęci są ulgą.</li>
              </ul> 
              <p className="text">Cała reszta pracowników i podatników czerpać będzie z IKZE istotne korzyści. Dla tej grupy opinia jest jasna –  każdy powinien założyć sobie IKZE.</p>

              <h2 style={{marginTop: "20px", marginBottom: "20px"}}>
                Jakie instrumenty można wykorzystać w ramach swojego rachunku IKZE?
              </h2>
              <div className="line mb-3"></div>
              <div className="text">
                <p className="text">IKZE stanowi rodzaj antypodatkowego opakowania, w którym umieścimy następujące rodzaje produktów finansowych:</p>
                <ul>
                  <li>
                    <h4 style={{color: "#000"}}>Konta oszczędnościowe</h4>
                    <p className="text">Zwykłe depozyty bankowe z pewnością zasługiwałyby na dobre opinie najbardziej zachowawczych, nieskorych do ryzyka klientów. Wobec niskich stóp procentowych jest to jednak rozwiązanie mało efektywne –  zyski mogą nie przekroczyć nawet inflacji. Aktualnie takie rozwiązanie, choć bezpieczne, nie odgrywa więc na rynku istotnej roli.</p>
                  </li>
                  <li>
                    <h4 style={{color: "#000"}}>Dobrowolne fundusze emerytalne</h4>
                    <p className="text">To także dobre rozwiązanie dla tych, którzy wolą unikać nadmiernego ryzyka i nie mają czasu bądź chęci na to, by zgłębiać tajniki inwestowania. DFE zbierają również dobre opinie ze strony tych, którym zależy na zysku i minimalizacji kosztów. Pod postacią Dobrowolnych Funduszy Emerytalnych kryje się jednolity fundusz zarządzany przez powszechne towarzystwo emerytalne. Taki produkt oferuje na naszym rynku szereg podmiotów, jak choćby PKO PTE, Nationale Nederlanden, Metlife, Generali, Allianz, PZU. Sytuacja w rankingach wyników poszczególnych podmiotów jest dość dynamiczna i aby wyrobić sobie o nich opinie, warto sprawdzić dane dla dłuższego okresu.</p>
                  </li>
                  <li>
                    <h4 style={{color: "#000"}}>Inwestycyjne ubezpieczenia na życie</h4>
                    <p className="text">Inwestujemy tutaj wpłacaną składkę na polisę na fundusze wskazane przez ubezpieczyciela. Ten rodzaj produktu dostępny jest m.in. w ofertach Nationale Nederlanden, Metlife, Generali, Allianz, czy np. Uniqa (dawniej AXA).</p>
                  </li>
                  <li>
                    <h4 style={{color: "#000"}}>Giełdowe papiery wartościowe</h4>
                    <p className="text">To oznacza inwestycje w akcje, obligacje czy inne dokumenty towarzyszące transakcjom na giełdzie. Inwestowanie w giełdowe papiery wartościowe nie jest dla wszystkich – trzeba mieć praktyczną wiedzę na temat inwestowania, bo poziom ryzyka jest wyższy. Taki produkt oferują m.in. mBank, DM Banku BPS, czy DM BOŚ.</p>
                  </li>
                  <li>
                    <h4 style={{color: "#000"}}>Fundusze inwestycyjne</h4>
                    <p className="text">Tu także można liczyć na wyższe zyski, ale za cenę ryzyka,. Fundusze inwestycyjne mogą również generować nieco wyższe koszty prowadzenia konta. Takie produkt znajdziemy np. w ofercie Esaliens, czyli dawniej Legg Mason.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <StickyBox>
              <CompareWidget isNarrow={true} />
            </StickyBox>
          </div>
        </div>
        <div className="row">
          <BlogPosts />
        </div>
      </div>
    </Layout>
  );
};

export default Opinions;
