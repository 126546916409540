import React from "react";
import styled from "styled-components";

import Star0 from "../../assets/images/icons/star-0.svg";
import Star1 from "../../assets/images/icons/star-1.svg";
import Star2 from "../../assets/images/icons/star-2.svg";
import Star3 from "../../assets/images/icons/star-3.svg";
import Star4 from "../../assets/images/icons/star-4.svg";
import Star5 from "../../assets/images/icons/star-5.svg";

export const StarsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    width: 30px;
    margin-right: 2px;
  }
  .stars {
    height: 30px;
    display: flex;
  }
  @media screen and (max-width: 768px) {
    .stars {
      justify-content: ${({centerMobile}) => (centerMobile ? "center" : "")};
      height: 20px;
    }
    img {
      width: 20px;
    }
  }
`;

export default function Stars({rating, centerMobile}) {

  var starArr = [];
  const rate = Math.round(rating * 10) / 10
  for (let i = 1; i <= 5; i++) {
    if (i <= rate) {
      starArr.push(Star5);
    }
      if (i > rate ) {
      let r = "" + rate
      r = parseInt(r.split('.', 2)[1])
      if(r >= 8 && r < 10) starArr.push(Star4); 
      if(r >= 6 && r < 8) starArr.push(Star3); 
      if(r >= 4 && r < 6) starArr.push(Star2);
      if(r >= 0 && r < 4) starArr.push(Star1); 
      break;
    }
  }
  starArr= (Object.assign(new Array(5).fill(Star0), starArr));

  return (
    <StarsWrapper centerMobile={centerMobile}>
      <div className="stars">
        {starArr.map((star, i) => {
          return <img src={star} key={i} alt=""/>;
        })}
      </div>
    </StarsWrapper>
  );
}
